<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6"
      ><v-card-text class="px-0 py-0">
        <v-card class="card-shadow border-radius-xl">
          <v-form ref="frm">
            <v-card-text class="card-padding">
              <v-row>
                <v-col cols="6">
                  <label class="text-md text-typo font-weight-bolder ms-1"
                    ><span class="red--text"><strong>* </strong></span>
                    File</label
                  >

                  <v-file-input
                    prepend-inner-icon
                    v-model="editedItem.file"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-3
                    "
                    dense
                    flat
                    filled
                    solo
                    height="46"
                    placeholder="Upload File"
                    :rules="[(v) => !!v || 'File is required']"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="card-padding d-flex justify-end">
              <v-btn
                @click="cancel"
                elevation="0"
                :ripple="false"
                height="46"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-ls btn-secondary
                  bg-light
                  py-3
                  px-6
                "
                >Cancel</v-btn
              >

              <v-btn
                @click="save"
                elevation="0"
                :ripple="false"
                height="46"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-ls btn-primary
                  bg-success
                  py-3
                  px-6
                "
                >Save</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-card-text>
    </v-container>
  </div>
</template>

<script>
import api from "../api";
export default {
  created() {},
  data() {
    return {
      overlay: false,
      editedItem: {
        file: null,
        file_name: "",
      },
      defaultItem: {
        file: null,
        file_name: "",
      },
    };
  },
  methods: {
    frmValidate() {
      return this.$refs.frm.validate();
    },
    cancel() {
      this.$refs.frm.reset();
      this.$refs.frm.resetValidation();
    },
    async save() {
      if (this.frmValidate()) {
        let filename = this.editedItem.file.name;
        let ext = filename.substring(
          filename.lastIndexOf(".") + 1,
          filename.length
        );
        // console.log("SAVING");
        if (ext == "xlsx") {
          this.overlay = true;
          let bodyFormData = new FormData();
          bodyFormData.append(
            "file",
            this.editedItem.file,
            this.editedItem.file.name
          );
          try {
            await api.create(bodyFormData);
            this.showSuccessAlert("Data Uploaded");
          } catch (error) {
            this.showWarningAlert(error);
          } finally {
            this.overlay = false;
            this.editedItem = this.defaultItem;
          }
        } else {
          this.showWarningAlert("Please use .xlsx file ");
        }
      }
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
        showConfirmButton: false,
      });
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",

        icon: "error",
        showConfirmButton: true,
      });
    },
  },
};
</script>

<style></style>
