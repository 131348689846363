import axios from "axios";

export default {
  create(item) {
    return axios
      .post("dispatch/Upload-RM-Arrival", item)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  //'dispatch/Upload-RM-Arrival'
};
